<template>
	<div class="admin-container">
		<Head />
		<div class="admin-main flex">
			<div class="admin-aside admin-media">
				<OrderNav></OrderNav>
			</div>
			<div class="admin-col whole_000 whole_fff flex_bd">
				<div class="order_tab">
					<div class="or_inpp">
						<el-input v-model="odrsn" :placeholder="$t('zbplaceholder')" class="order_input">
							<i slot="prefix" class="el-input__icon el-icon-search" @click="search"></i>
						</el-input>
					</div>
					<el-tabs v-model="activeName" @tab-click="handleClick">
						<el-tab-pane :label="$t('orTab1')" name="1">
							<div class="order-table">
								<el-table :data="tableData" style="width: 100%">
									<el-table-column prop="orderSn" :label="$t('orth1')">
									</el-table-column>
									<el-table-column prop="createTime" :label="$t('apith2')">
									</el-table-column>
									<el-table-column prop="unit" :label="$t('orth2')">
									</el-table-column>
									<el-table-column prop="type" :label="$t('orth3')">
									</el-table-column>
									<el-table-column prop="name" :label="$t('orth4')">
									</el-table-column>
									<el-table-column prop="amount" :label="$t('wtth4')">
									</el-table-column>
									<el-table-column prop="money" :label="$t('orth5')">
									</el-table-column>
									<el-table-column prop="commission" :label="$t('czTxt8')">
									</el-table-column>
									<template slot="empty" class="exte_empty">
										<img src="../../assets/photo/exte1.png" alt="">
										<p>{{$t('czEmpty')}}</p>
									</template>
								</el-table>
							</div>
						</el-tab-pane>
						<el-tab-pane :label="$t('orTab2')" name="2">
							<div class="order-table">
								<el-table :data="tableData" style="width: 100%">
									<el-table-column prop="orderSn" :label="$t('orth1')">
									</el-table-column>
									<el-table-column prop="createTime" :label="$t('apith2')">
									</el-table-column>
									<el-table-column prop="unit" :label="$t('orth2')">
									</el-table-column>
									<el-table-column prop="type" :label="$t('orth3')">
									</el-table-column>
									<el-table-column prop="name" :label="$t('orth4')">
									</el-table-column>
									<el-table-column prop="amount" :label="$t('wtth4')">
									</el-table-column>
									<el-table-column prop="money" :label="$t('orth5')">
									</el-table-column>
									<el-table-column prop="commission" :label="$t('czTxt8')">
									</el-table-column>
									<template slot="empty" class="exte_empty">
										<img src="../../assets/photo/exte1.png" alt="">
										<p>{{$t('czEmpty')}}</p>
									</template>
								</el-table>
							</div>
						</el-tab-pane>
						<el-tab-pane :label="$t('orTab3')" name="3">
							<div class="order-table">
								<el-table :data="tableData" style="width: 100%">
									<el-table-column prop="orderSn" :label="$t('orth1')">
									</el-table-column>
									<el-table-column prop="createTime" :label="$t('apith2')">
									</el-table-column>
									<el-table-column prop="unit" :label="$t('orth2')">
									</el-table-column>
									<el-table-column prop="type" :label="$t('orth3')">
									</el-table-column>
									<el-table-column prop="name" :label="$t('orth4')">
									</el-table-column>
									<el-table-column prop="amount" :label="$t('wtth4')">
									</el-table-column>
									<el-table-column prop="money" :label="$t('orth5')">
									</el-table-column>
									<el-table-column prop="commission" :label="$t('czTxt8')">
									</el-table-column>
									<template slot="empty" class="exte_empty">
										<img src="../../assets/photo/exte1.png" alt="">
										<p>{{$t('czEmpty')}}</p>
									</template>
								</el-table>
							</div>
						</el-tab-pane>
						<el-tab-pane :label="$t('orTab4')" name="0">
							<div class="order-table">
								<el-table :data="tableData" style="width: 100%">
									<el-table-column prop="orderSn" :label="$t('orth1')">
									</el-table-column>
									<el-table-column prop="createTime" :label="$t('apith2')">
									</el-table-column>
									<el-table-column prop="unit" :label="$t('orth2')">
									</el-table-column>
									<el-table-column prop="type" :label="$t('orth3')">
									</el-table-column>
									<el-table-column prop="name" :label="$t('orth4')">
									</el-table-column>
									<el-table-column prop="amount" :label="$t('wtth4')">
									</el-table-column>
									<el-table-column prop="money" :label="$t('orth5')">
									</el-table-column>
									<el-table-column prop="commission" :label="$t('czTxt8')">
									</el-table-column>
									<template slot="empty" class="exte_empty">
										<img src="../../assets/photo/exte1.png" alt="">
										<p>{{$t('czEmpty')}}</p>
									</template>
								</el-table>
							</div>
						</el-tab-pane>
						<el-tab-pane :label="$t('orTab5')" name="4">
							<div class="order-table">
								<el-table :data="tableData" style="width: 100%">
									<el-table-column prop="orderSn" :label="$t('orth1')">
									</el-table-column>
									<el-table-column prop="createTime" :label="$t('apith2')">
									</el-table-column>
									<el-table-column prop="unit" :label="$t('orth2')">
									</el-table-column>
									<el-table-column prop="type" :label="$t('orth3')">
									</el-table-column>
									<el-table-column prop="name" :label="$t('orth4')">
									</el-table-column>
									<el-table-column prop="amount" :label="$t('wtth4')">
									</el-table-column>
									<el-table-column prop="money" :label="$t('orth5')">
									</el-table-column>
									<el-table-column prop="commission" :label="$t('czTxt8')">
									</el-table-column>
									<template slot="empty" class="exte_empty">
										<img src="../../assets/photo/exte1.png" alt="">
										<p>{{$t('czEmpty')}}</p>
									</template>
								</el-table>
							</div>
						</el-tab-pane>
					</el-tabs>
					<div class="exte-box">
						<el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage"
							layout="prev, pager, next" :total="total" :background="true"></el-pagination>
					</div>
				</div>
			</div>
		</div>
		<Foot />
	</div>
</template>

<script>
	import {
		orderSelf
	} from '@/api/api/otc'
	import OrderNav from '@/components/OrderNav.vue'
	import Head from '@/components/Head.vue'
	import Foot from '@/components/Foot.vue'
	export default {
		name: 'Home',
		components: {
			Head,
			OrderNav,
			Foot
		},
		data() {
			return {
				odrsn: '',
				activeName: '1',
				tableData: [],
				currentPage: 1,
				pageSize: 10,
				total: 10
			};
		},
		mounted() {
			this.getList()
		},
		methods: {
			getList() {
				orderSelf({
					orderSn: this.odrsn,
					status: this.activeName,
					pageNo: this.currentPage - 1,
					pageSize: this.pageSize
				}).then(res => {
					this.tableData = res.data.content
					this.total = res.data.totalElements
					this.pageSize = res.data.size
				})
			},
			handleCurrentChange(val) {
				this.currentPage = val
				this.getList()
			},
			handleClick() {
				this.currentPage = 1
				this.getList()
			},
			search() {
				this.getList()
			}
		}
	}
</script>
